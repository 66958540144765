<template>
    <div class="market container">
      <div class="title">{{$t('app.invest.market')}}</div>
      <div class="content">
          <div class="item" v-for="item in marketList" :key="item.id">
              <header>
                <div>#{{item.id}}</div>
                <div>{{item.shares_owner | addressCharacter}}</div>
              </header>
              <div>
                <span>{{$t('app.invest.Stock')}}</span>
                <div>{{item.shares_amount | numFilter5}}</div>
              </div>
              <div>
                <span>{{$t('app.invest.price')}}</span>
                <div>{{item.shares_price | numFilter5}}U</div>
              </div>
              <button v-if="address && address == item.shares_owner.toLocaleLowerCase()" @click="withdrawMsd(item)">{{$t('app.invest.withdrawAll')}}</button>
              <button v-else @click="buyMsd(item)">{{$t('app.invest.Buy')}}</button>
          </div>
      </div>
      <div class="empty" v-if="marketList.length <= 0">
        <div class="empty-bg"></div>
        <div class="tips">{{ $t('com.empty') }}</div>
      </div>
      <div  v-else style="display: flex;width:100%;justify-content:center">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="prev, pager, next"
          small
          :total="page.total">
        </el-pagination>
      </div>
    </div>
  </template>
  
  <script>
  import { ethers } from 'ethers'
  import ERC20 from '../../contracts/ERC20.json'
  import Fare from '../../contracts/Fare.json'
  import { initSigner } from '../../utlis/index'
  import { get, post } from '../../utlis/axios'
  import dayjs from 'dayjs';
  export default {
    data () {
      return {
        contracts: {},
        address: '',
        min: 300,           // 步进值
        UNumber: 300,      // USDT数量
        total: 5190,      // 总可购买量
        limit: 0,         // 剩余可购买量
        balanceUSDT: 0,
        allowanceUSDT: 0,
        marketList:[],
        page:{
          size:10,
          page:1,
          total:0,
        },
        loading:{
          buy:false,
        }
      }
    },
    mounted () {
        this.getLocalData()
        let tries = 0,_this = this;
        setTimeout(function initTimer() {
            //@ts-ignore
            if (!window.ethereum && ++tries < 20) return setTimeout(initTimer, 50);
            else _this.init()
        })
    },
    beforeDestroy () {
      clearInterval(this.initTimer)
    },
    methods: {
      async init () {
        let { address, provider, signer } = await initSigner()
        this.address = address.toLocaleLowerCase()
        this.contracts.USDT = new ethers.Contract(this.$common.USDT, ERC20, provider).connect(signer)
        this.contracts.Fare = new ethers.Contract(this.$common.Fare, Fare, provider).connect(signer)
        this.getData()
        window.contracts = this.contracts;
        clearInterval(this.initTimer)
        this.initTimer = setInterval(() => {
          this.getData()
          this.getLocalData()
        }, 20000);
      },
      async getData () {
          if (!this.address) return false
          this.contracts.USDT.allowance(this.address,this.contracts.Fare.address).then(res => {
              this.allowanceUSDT = Number(ethers.utils.formatUnits(res,18))
          })
          this.contracts.USDT.balanceOf(this.address).then(res => {
              this.balanceUSDT = Number(ethers.utils.formatUnits(res,18))
          })
      },
      getLocalData(){
          get('/market',{page:this.page.page,length:this.page.size}).then(res => {
              this.marketList = res.data || [];
              this.page.total = res.length || 0;
          })
      },
      handleSizeChange(val) {
        this.page.size = val;
        this.getLocalData();
      },
      handleCurrentChange(val) {
        this.page.page = val;
        this.getLocalData();
      },
      async withdrawMsd(item){
        if(item.loading) return;
        item.loading = true;
        let sign = await this.signature('WITHDRAW','WITHDRAW')
        console.log(item.shares_owner,
         item.id,
         sign);
        post('/withdrawToMarket',{
          address:item.shares_owner,
          id:item.id,
          ...sign
        }).then(res => {
            if(res.code == 200){
                item.loading = false;
                this.$message.success(this.$t('app.WithdrawSuccessful'))
                this.getData()
            }else{
                item.loading = false;
                console.log(res);
                this.$message.error(this.$t('app.WithdrawFailed'))
            }
        }).catch(e => {
            item.loading = false;
            console.error(e);
            this.$message.error(this.$t('app.WithdrawFailed'))
        })

      },
      async buyMsd (item) {
        if(item.loading) return;
        else if(Number(item.shares_price) > Number(this.balanceUSDT)) return this.$message.warning(this.$t('app.InsufficientUSDTbalance ')+item.shares_price)
        item.loading = true;
        if(this.allowanceUSDT < Number(item.shares_price)){
          try{
            this.$message.warning(this.$t('app.noApproveInfo'))
            let tx = await this.contracts.USDT.approve(this.contracts.Fare.address,ethers.constants.MaxUint256)
            await tx.wait()
          }catch(e){
            console.error(e);
            this.loading.invest = false;
            setTimeout(()=>{
              this.$message.error(this.$t('app.ApproveFailed'));
            },1000)
            return;
          }
        }
        post('/buyToMarket',{address:this.address,id:item.id}).then(res => {
            this.contracts.Fare.buyToMarket(item.id,res.address,res.price,res.sign).then(async (result) => {
                await result.wait()
                item.loading = false;
                this.$message.success(this.$t('msg.purchaseSucc'))
                this.getData()
            }).catch((err) => {
                item.loading = false;
                console.log(err);
                this.$message.error(this.$t('msg.PurchaseFailed'))
            });
        }).catch(e => {
            item.loading = false;
            console.error(e);
            this.$message.error(this.$t('msg.PurchaseFailed'))
        })
        
      },
      //验证签名，
      async signature (name,name2){
          let { provider } = await initSigner();
          //@ts-ignore
          // let provider1 = new ethers.providers.Web3Provider(web3.currentProvider);
          // 签名信息
          let timestamp = dayjs().unix();
          const message = {
              name: name,
              timestamp: timestamp
          };
          // 签名说明
          const domain = {
              name: name2
          };
          // 签名信息类型  
          const types = {
              Mail: [
                  { name: 'name', type: 'string' },
                  { name: 'timestamp', type: 'uint' }
              ]
          };
          //签名
          let sign;
          try{
              sign = await provider.getSigner()._signTypedData(domain, types, message)
          }catch(e){
              console.error(e);
              return false
          }
          // console.log(ethers.utils.verifyTypedData(domain, types, message, sign));
          return {
              sign: sign,
              timestamp: timestamp,
          };
          //验签
          // console.log('1', domain, '2', types, '3', message, '4', sign, '验签信息');
          // provider.utils.verifyTypedData(domain, types, message, sign)
      },
      // 授权USDT
      approve () {
        const max = ethers.constants.MaxUint256
        this.contracts.USDT.approve(this.$common.Community, max).then(async (result) => {
          await result.wait()
          this.$message.success(this.$t('msg.approveSucc'))
          this.getData()
        }).catch((err) => {
          console.log(err);
          this.$message.error(this.$t('msg.approveFaile'))
        });
      },
    },
    components: {}
  }
  </script>
  
  <style lang="scss" scoped>
  .market {
    padding: 1.4rem 0.44rem 0.4rem;
    .empty {
      font-size: 0.28rem;
      padding: 0.4rem 0;
      .empty-bg {
        width: 2.9rem;
        height: 1.87rem;
        margin: 0 auto;
        background: url('../../assets/img/empty.png') left top / 100% 100% no-repeat;
      }
      .tips {
        margin-top: 0.2rem;
        text-align: center;
      }
    }
    .title {
      width: 5.74rem;
      height: 1.66rem;
      line-height: 1.28rem;
      font-size: 0.36rem;
      text-align: center;
      font-weight: bold;
      margin: 0 auto;
      background: url('../../assets/img/web-title-bg.png') left top / 100% 100% no-repeat;
    }
    .content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item{
            margin-bottom: .3rem;
            width: 45%;
            padding: .3rem;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            background: url(../../assets/img/market-item-bg.png) left top / 100% 100% no-repeat;
            header{
              font-size: .22rem;
              color: #9672C5;
            }
            >div,>header{
               display: flex;
            }
            >header{
              justify-content: space-between;
            }
            >div{
              align-items: center;
              line-height: 0.55rem;
              flex-direction: column;
              >span{
                font-size: .22rem;
              }
              >div{
                color: #3E9DD2;
                font-weight: bold;
                font-size: .3rem;
              }
            }
            button{
              border: none;
              outline: none;
              font-size: .26rem;
              color: #fff;
              height: .7rem;
              background: url(../../assets/img/market-btn.png) left top / 100% 100% no-repeat;
            }
        }
    }
  }
  </style>
  